// Loading.js
import React from 'react';

import './Loading.css'
const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-shimmer"></div>
    </div>
  );
};

export default Loading;
